<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        class="col-12"
        label="Account ID"
        v-model="formData.accountId">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="username"
        v-model="formData.username">
      </sgv-input-text>

      <sgv-input-password
        class="col-6"
        label="password"
        v-model="formData.password">
      </sgv-input-password>
    </div>

    <button
      class="btn btn-warning"
      @click="updateData">
      แก้ไข
    </button>
  </div>
</template>

<script>
import { CONFIG_DEVICE } from './graph'


export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        accountId: '',
        username: '',
        password: '',
      }
    }
  },
  methods: {
    updateData () {
      this.$apollo.mutate({
        mutation: CONFIG_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          input: this.formData
        }
      }).then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
